<template>
  <div
    class="ErrorText"
    :class="{
      WithBackground: withBackground}
    ">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'ErrorText',
  props: {
    text: {
      type: String,
      required: true,
    },
    withBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .ErrorText
    margin-top 10px
    margin-bottom 10px
    color $color_error
    &.WithBackground
      padding 10px
      background-color $color_error
      color #fff
      border-radius 5px

</style>
